import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BsClipboard2PlusFill, BsClipboard2CheckFill } from "react-icons/bs";

const CodeBlock = () => {
  const [copied, setCopied] = useState(false);
  const codeString = `curl -X POST https://mail.zanbaghi.com \
  -H 'Content-Type: application/json' \
  -d '{ "email": "your-email@example.com" }'`;

  return (
    <div style={{ cursor: `${copied ? "default" : "copy"}` }}>
      <CopyToClipboard text={codeString} onCopy={() => setCopied(true)}>
        <code
          style={{
            color: "white",
            overflow: "auto",
            display: "block",
            fontSize: "0.8em",
          }}
        >
          <pre>
            {`curl -X POST https://mail.zanbaghi.com \
  -H 'Content-Type: application/json' \
  -d '{ "email": "`}
            <span style={{ color: "yellow" }}>your-email@example.com</span>
            {`" }'`}
          </pre>
        </code>
      </CopyToClipboard>
    </div>
  );
};

export default CodeBlock;
