import React, { useState } from "react";
import LoadingAnimation from "./LoadingAnimation";
import { RiSendPlane2Line } from "react-icons/ri";

const TextInputWithButton = () => {
  const [inputText, setInputText] = useState("");
  const [responseText, setResponseText] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  const handleButtonClick = () => {
    setIsFetching(true);
    setResponseText("");
    if (inputText.trim() !== "") {
      fetch("https://ai.zanbaghi.com", {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({ text: inputText }),
      })
        .then((response) => response.json())
        .then((data) => {
          setResponseText(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setResponseText("Error fetching data. Please try again later.");
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputText !== "") {
      event.preventDefault(); // Prevent form submission
      handleButtonClick(); // Call the function to handle button click
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          marginBottom: "20px",
        }}
      >
        <input
          type="text"
          value={inputText}
          onChange={handleChange}
          onKeyUp={handleKeyPress}
          placeholder="Chat with my LLM..."
        />
        <button
          className="send-button"
          onClick={handleButtonClick}
          disabled={isFetching || inputText === ""}
        >
          <RiSendPlane2Line />
        </button>
      </div>
      <div
        className={isFetching ? "loading-container" : "response-text-container"}
      >
        {isFetching && <LoadingAnimation />}
        {responseText && <b className="response-text">{responseText}</b>}
      </div>
    </div>
  );
};

export default TextInputWithButton;
